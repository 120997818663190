export const environment = {
  production: true,
  hmr: false,
  mediaApiEndpoint: 'https://dmcmediaservice-staging.azurewebsites.net/api/v1.0/',
  mediaApiEndpoint_V2: 'https://dmcmediaservice-staging.azurewebsites.net/api/v2.0/',
  adminApiEndpoint: 'https://dmcmediaservice-staging.azurewebsites.net/api/v1.0/',
  dmcUrl: 'https://staging.dmcpro.net',
  dmcApiurl:'https://staging.dmcpro.net/dmc-api',
  redirectUri:'https://mlstaging.dmcpro.net/login/',
  dashboardSeeMoreSize: 75
};
