import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Office } from 'src/app/models/office.model';
import {
  addMediaToGalleries,
  createUserGallery,
  getUserGalleries,
  removeMediaFromGalleries
} from 'src/app/ngrx/actions/galleries.actions';
import { selectUserOffices } from 'src/app/ngrx/selectors/offices.selector';
import { GalleryCreateCommand } from 'src/app/services/gallery.service';
import SwiperCore, { Keyboard, Navigation, Pagination } from 'swiper';
import { ContentCreateDialogComponent } from '../../components/dialogs/content-create-dialog/content-create-dialog.component';
import { Album } from '../../models/album.model';
import { Gallery } from '../../models/gallery.model';
import { Media, MediaMetadata } from '../../models/media.model';
import { Permissions } from '../../models/permissions';
import {
  addMediaToAlbums,
  createUserAlbum,
  getUserAlbums,
  getUserSharedAlbums,
  removeMediaFromAlbums
} from '../../ngrx/actions/albums.actions';
import { getIsMediaLiked, getMediaMetadataById, setIsMediaLiked } from '../../ngrx/actions/media.actions';
import { addMediaToPocket } from '../../ngrx/actions/pocket.actions';
import { AppState } from '../../ngrx/reducers';
import { doesPermissionExist, selectUser } from '../../ngrx/selectors/user.selector';
import { AlbumCreateCommand } from '../../services/album.service';

SwiperCore.use([Keyboard, Pagination, Navigation]);

@Component({
  selector: 'app-quick-look-dialog',
  templateUrl: './quick-look-dialog.component.html',
  styleUrls: ['./quick-look-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuickLookDialogComponent implements OnInit {
  private mediaId: number;
  private unsubscribe$ = new Subject<void>();
  public isInPocket: boolean = false;
  public galleryMedia: Media[];
  media: Media;
  galleries: Gallery[];
  albums: Album[]=[];
  sharedAlbums: Album[]=[];
  canAddMediaToGallery: boolean;
  selectedAlbums: Album[] = [];
  selectedGalleries: Gallery[] = [];
  metadata$: Observable<MediaMetadata>;
  public isPlaying: boolean;
  loggedUserId: any;
  userOfficeId: number;
  userOffices: Office[];
  @Output() addToPocketEvent: EventEmitter<string> = new EventEmitter();
  @Output() likeMediaEvent: EventEmitter<{ mediaId: string; newVal: boolean }> = new EventEmitter();
  isLiked: boolean;
  pocketIds: number[] = [];
  private isLoaded: boolean;
  albums$;
  sharedAlbums$;
  galleries$;
  slideIndex=0;
  constructor(
    private store$: Store<AppState>,
    private router: Router,
    public dialogRef: MatDialogRef<QuickLookDialogComponent>,
    public albumDialog: MatDialog,
    public galleryDialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit() {
    this.media = this.data.media;
    this.galleryMedia = this.data.mediaGallery;
    this.slideIndex=this.data.slideIndex;
    
    
  
    this.store$
      .pipe(takeUntil(this.unsubscribe$), select(doesPermissionExist, { permission: Permissions.CanAddMediaToGallery }))
      .subscribe(res => {
        this.canAddMediaToGallery = res;
      });
      this.albums$=  this.store$.dispatch(getUserAlbums());
      this.sharedAlbums$=  this.store$.dispatch(getUserSharedAlbums());
    this.store$.dispatch(getUserGalleries());

    this.store$
    .pipe(
      takeUntil(this.unsubscribe$),
      select(state => state.galleries.items)
    )
    .subscribe(res => (this.galleries = res));

    this.store$.pipe(takeUntil(this.unsubscribe$),select(state=>state.albums.albums)).subscribe(res => {
      this.albums = res;
    });
    this.store$.pipe(takeUntil(this.unsubscribe$),select(state=>state.sharedAlbums.albums)).subscribe(res => {
      this.sharedAlbums = res;
    });

    this.store$.dispatch(getIsMediaLiked({ mediaId: +this.media.mediaId }));
    this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.media.isMediaLiked)
      )
      .subscribe(res => {
        if (res && res.length) {
          let match = res.find(m => m.mediaId === +this.media.mediaId);
          if (match) {
            this.isLiked = match.isLiked;
          }
        }
      });

    this.store$.dispatch(getMediaMetadataById({ mediaId: +this.media.mediaId, getRelatedImages: false }));
   

    this.store$.pipe(takeUntil(this.unsubscribe$), select(selectUser)).subscribe(res => {
      this.loggedUserId = res.userLoginId;
    });

    this.store$.pipe(takeUntil(this.unsubscribe$), select(selectUserOffices)).subscribe(res => {
      this.userOffices = res;
    });
    this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.media.mediaPocketIds)
      )
      .subscribe(mediaIds => {
        if (mediaIds && mediaIds.length > 0) {
          this.pocketIds = mediaIds;
          this.pocketIds.map(i => {
            if (i == this.media.mediaId) {
              this.isInPocket = true;
            }
          });
        }
      });
  }
  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  playAudio() {
    const audio = new Audio();
    audio.src = this.media.links.fileUrl;
    if (this.isPlaying) {
      audio.pause();
      this.isPlaying = false;
    } else if (this.isLoaded) {
      audio.play();
      this.isPlaying = true;
    } else {
      audio.load();
      audio.play();
      this.isPlaying = true;
      this.isLoaded = true;
    }
  }

  openDetailedView() {
    // can't use a link because angular won't close the dialog
    this.dialogRef.close();
    this.router.navigateByUrl('/media/' + this.media.mediaId);
  }

  addToPocket() {
    const itemExists = this.pocketIds.indexOf(+this.media.mediaId) == -1 ? false : true;
    if (!itemExists) {
      this.store$.dispatch(addMediaToPocket({ mediaId: +this.media.mediaId }));
    }
    this.isInPocket = true;
  }

  likeImage() {
    let likesAlbum = this.albums.find(a => a.isFavorite);
    if (likesAlbum) {
      this.isLiked = !this.isLiked;
      this.store$.dispatch(
        setIsMediaLiked({ mediaId: +this.media.mediaId, likesAlbumId: likesAlbum.id, newValue: this.isLiked })
      );
    }
  }
  addMediaToSelectedAlbums(selectedAlbumsObj: { addedAlbumIds: number[]; removedAlbumIds: number[] }) {
    if (selectedAlbumsObj) {
      let currentlySelectedAlbums = [];
      let unselectedAlbums = [];
      let currentlySelectedIds=[];
if (this.media.metaData){
   currentlySelectedIds = this.media.metaData.albums.map(a => {
    return a.mediaGroupId;
  });
}
     
      // reconcile unselected albums with current album state
      let unselectedMatches = selectedAlbumsObj.removedAlbumIds.filter(id => currentlySelectedIds.indexOf(id) !== -1);
      if (unselectedMatches && unselectedMatches.length > 0) {
        unselectedAlbums = unselectedMatches;
      }
      // reconcile newly added albums from current state
      let selectedMatches = selectedAlbumsObj.addedAlbumIds.filter(id => currentlySelectedIds.indexOf(id) === -1);
      if (selectedMatches && selectedMatches.length > 0) {
        currentlySelectedAlbums = selectedMatches;
      }

      this.store$.dispatch(
        addMediaToAlbums({ mediaId: this.media.mediaId, selectedAlbumIds: currentlySelectedAlbums })
      );
      this.store$.dispatch(removeMediaFromAlbums({ mediaId: +this.media.mediaId, selectedAlbumIds: unselectedAlbums }));
    }
  }
  addMediaToSelectedGalleries(selectedGalleriesObj: { addedGalleryIds: number[]; removedGalleryIds: number[] }) {
    if (selectedGalleriesObj) {
      let currentlySelectedGalleries = [];
      let unselectedGalleries = [];

      let currentlySelectedIds = this.media.metaData.galleries.map(a => {
        return a.mediaGroupId;
      });
      // reconcile unselected albums with current album state
      let unselectedMatches = selectedGalleriesObj.removedGalleryIds.filter(
        id => currentlySelectedIds.indexOf(id) !== -1
      );
      if (unselectedMatches && unselectedMatches.length > 0) {
        unselectedGalleries = unselectedMatches;
      }
      // reconcile newly added albums from current state
      let selectedMatches = selectedGalleriesObj.addedGalleryIds.filter(id => currentlySelectedIds.indexOf(id) === -1);
      if (selectedMatches && selectedMatches.length > 0) {
        currentlySelectedGalleries = selectedMatches;
      }

      this.store$.dispatch(
        addMediaToGalleries({ mediaId: +this.media.mediaId, selectedGalleryIds: currentlySelectedGalleries })
      );
      this.store$.dispatch(
        removeMediaFromGalleries({ mediaId: +this.media.mediaId, selectedGalleryIds: unselectedGalleries })
      );
    }
  }
  createAlbum(event: any) {
    const albumDialogRef = this.albumDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: true,
        pocket: [this.media],
        albums: this.albums,
      },
    });

    albumDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let request = new AlbumCreateCommand();
          request.Name = result.Name;
          request.Description = result.Description;
          request.CoverImage = result.CoverImage;
          request.UserId = this.loggedUserId;
          request.MediaId = +result.MediaId;
          request.MediaIds= [+result.MediaId];
          this.store$.dispatch(createUserAlbum({ newAlbumDto: request }));
          this.store$.dispatch(getUserAlbums());
          this.store$.dispatch(getUserGalleries());
        }
      });
  }

  createGallery(event: any) {
    const galleryDialogRef = this.galleryDialog.open(ContentCreateDialogComponent, {
      width: '700px',
      data: {
        isAlbum: false,
        pocket: [this.media],
        offices: this.userOffices,
      },
    });

    galleryDialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(result => {
        if (result) {
          let gallery = new GalleryCreateCommand();
          gallery.Name = result.Name;
          gallery.Description = result.Description;
          gallery.CoverImage = result.CoverImage;
          gallery.CreatedByUserId = this.loggedUserId;
          gallery.UpdatedByUserId = this.loggedUserId;
          gallery.IsPublic = false;
          gallery.MediaId = +result.MediaId;
          gallery.MediaIds = [+result.MediaId];
          gallery.OfficeId = +result.OfficeId;
          gallery.StatusId = 4; // This is "Draft" status

          this.store$.dispatch(createUserGallery({ galleryDto: gallery }));
        }
      });
  }

  onSlideChange(event){
    this.selectedAlbums = [];
    this.selectedGalleries = [];
    this.isInPocket=false;
    this.isLiked=false;
    this.store$.dispatch(getMediaMetadataById({ mediaId: +this.galleryMedia[event[0].activeIndex].mediaId
      , getRelatedImages: false }));
      this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.media.items)
      )
      .subscribe(res => {
        let mediaWithMetadata = res.find(m => +m.mediaId === +this.galleryMedia[event[0].activeIndex].mediaId);
        if (mediaWithMetadata && mediaWithMetadata.metaData) {
          this.media = mediaWithMetadata;
          if (this.media.metaData){
            this.selectedAlbums = this.media.metaData.albums;
            this.selectedGalleries = this.media.metaData.galleries;
          }
         
        }
      });

      this.store$
      .pipe(
        takeUntil(this.unsubscribe$),
        select(state => state.media.mediaPocketIds)
      )
      .subscribe(mediaIds => {
        if (mediaIds && mediaIds.length > 0) {
          this.pocketIds = mediaIds;
          this.pocketIds.map(i => {
            if (i == +this.galleryMedia[event[0].activeIndex].mediaId) {
              this.isInPocket = true;
            }
          });
        }
      });
      this.store$.dispatch(getIsMediaLiked({ mediaId: +this.galleryMedia[event[0].activeIndex].mediaId }));
      this.store$
        .pipe(
          takeUntil(this.unsubscribe$),
          select(state => state.media.isMediaLiked)
        )
        .subscribe(res => {
          if (res && res.length) {
            let match = res.find(m => m.mediaId === +this.galleryMedia[event[0].activeIndex].mediaId);
            if (match) {
              this.isLiked = match.isLiked;
            }
          }
        });
  }
  
}
